exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-articles-tsx": () => import("./../../../src/templates/articles.tsx" /* webpackChunkName: "component---src-templates-articles-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-haul-tsx": () => import("./../../../src/templates/haul.tsx" /* webpackChunkName: "component---src-templates-haul-tsx" */),
  "component---src-templates-help-tsx": () => import("./../../../src/templates/help.tsx" /* webpackChunkName: "component---src-templates-help-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-merchants-tsx": () => import("./../../../src/templates/merchants.tsx" /* webpackChunkName: "component---src-templates-merchants-tsx" */)
}

