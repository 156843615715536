import HTTP from '../lib/http';
const { isProd } = require('../lib/env');

// 获取商品详情
export function getProductDeatil(productId: string, promo: boolean = true, groupBuy: boolean = false) {
    return HTTP.get(`/api/v1/products/${productId}`, {
        params: { promo, groupBuy }
    });
}

// 获取商品的商家及品牌信息
export function getProductRelated(productId: string) {
    return HTTP.get(`/api/v1/products/${productId}/related`);
}

// 获取相似商品
export function getSimilarProduct(productId: string) {
    return HTTP.get(`/api/v2/products/${productId}/similar/context/PRODUCT_DETAILS`, {
        headers: {
            "Content-Type": "application/x-protobuf-json"
        },
        data: {}
    })
}

// 编辑地址
export function editAddress(id: string, data: any) {
    return HTTP.post(`/api/v1/profile/addressbook/${id}`, data);
}

// 加入购物袋
export function addShoppingCart(data: any) {
    return HTTP.post('/api/v2/shoppingcart', data);
}

// 立即购买
export function expressBuy(data: any) {
    return HTTP.post('/api/v1/express-buy/sku', data)
}


// 下单
export function order(data: any) {
    return HTTP.post('/api/v1/orders', data);
}

// 获取weixin config参数
export function getWxConfigParam() {
    return HTTP.get(`/api/v1/wechat-jsticket?url=${encodeURIComponent(window.location.href.split('#')[0])}`);
}


// 获取openId
export function getOpenId(code: string) {
    return HTTP.get(`/api/v1/wechat/openId?code=${code}`);
}

// 获取商家运费问答信息
export function getMerchantQA(merchantId: string) {
    return HTTP.get(`/api/v1/merchants/${merchantId}/domesticshipping`);
}

// 获取官网详情翻译
export function getTranslate(productId: string) {
    return HTTP.get(`https://5thave-prod.bieyangapp.com/api/v1/translate/product/${productId}`);
}

// 获取购物袋信息
export function getCartInfo() {
    return HTTP.get('/api/v2/shoppingcart/cart-meta-info', {
        headers: {
            "Content-Type": "application/x-protobuf-json"
        },
        data: {}
    });
}

// 购物袋空态内推荐商品
export function discover(f: number = 0, t: number = 10) {
    return HTTP.get('/api/v2/discover', {
        params: {
            pr: 'f0-',
            dr: '0-100',
            f,
            t
        }
    });
}


// 获取商家列表
export function getMerchants() {
    return HTTP.get('/api/v2/merchants')
}


// 获取商家列表
export function getMerchantsList() {
    return HTTP.get('/api/v2/merchants');
}

interface Identity {
    firstName: string,
    lastName: string,
    idNumber: string
}

// 修改亲友付订单支付人信息
export function updateAddonPayer(orderId: string, params: Identity) {
    return HTTP.post(`/api/v1/orders/${orderId}/payment/friend-pay/checkpayeridentity`, params);
}

// 获取商品列表
export function getProductComment(productId: string, size = 10, from = 0, sort = 'posted_at', tag = '') {
    return HTTP.get(`/api/v1/product-comment/products/${productId}/comments`, {
        params: {
            f: from,
            t: size,
            s: sort,
            tag
        }
    });
}

// 点赞商品评论
export function likeProductComment(productId: string, commentId: string) {
    return HTTP.post(`/api/v1/product-comment/products/${productId}/comments/${commentId}/likes`);
}

// 取消点赞商品评论
export function unlikeProductComment(productId: string, commentId: string) {
    return HTTP.delete(`/api/v1/product-comment/products/${productId}/comments/${commentId}/likes`);
}

// 领取代金券
export function getCoupon(link: any) {
    return HTTP.post(link)
}

// 根据商品获取本人优惠券信息
export function getCouponByProduct(productId: string) {
    return HTTP.get(`/api/v1/claim/popup/SUITPRODUCT/${productId}`);
}

// 领取优惠券
export function receiveCoupon(couponId: string, couponType: string) {
    return HTTP.post(`/api/v1/claim/${couponType}/${couponId}`);
}

// 获取所有类目
export function getCatrgroies() {
    return HTTP.get(`/api/v2/productcategories`)
}

// 获取类目商品
export function getCategoryProducts(cids: string) {
    return HTTP.get(`/api/v2/discover?cids=${cids}&t=15`)
}

// 新人大礼包
export function getGiftAmount() {
    return HTTP.get(`/api/v1/wechat/miniprogram/gift-amount`)
}

// 获取晒单列表
export function getHaulComment(q: string) {
    return HTTP.get(`/api/v2/product_comment/haul?${q}`)
}

// 类目页筛选discover
export function categoryDiscover(q: string) {
    return HTTP.post('/api/v3/discover', {
        q: q
    }, {
        headers: {
            "Content-Type": "application/x-protobuf-json"
        },
    });
}

export function getSizeInfo(productId: string) {
    return HTTP.get(
        `/api/v1/sizecharts/products/${productId}`
    );
}

export function getHaulDetail(p_id: string, c_id: string) {
    // const api = https://5thave-prod.bieyangapp.com/api/v1/product-comment/products/michaelkors_35H1T2JT3C/comments/4b9d0648-61f6-4c9d-b8a1-3fc63d3e481b/replies?f=0&t=1&s=posted_at
    return HTTP.get(
        `/api/v1/product-comment/products/${p_id}/comments/${c_id}/replies?f=0&t=1&s=posted_at`
    )
}

/** 获取curation 页面 */
const API_ARTICLE_LIST = `https://baleen2-prod.bieyangapp.com/v1/articles/_search`

const PAEG_SIZE = 30
export function getArticles(tag: string, page_index: number) {
    return HTTP.get(`${API_ARTICLE_LIST}`, {
        params: {
            f: page_index * PAEG_SIZE,
            t: (page_index + 1) * PAEG_SIZE,
            tags: tag,
        }
    })
}

/** 获取curation 页面 */