import * as React from 'react'
import FloatingWidget from '@/components/FloatingWidget'
import './about.scss'
import banner2 from '@/images/banner2.png'
import mbanner2 from '@/images/mbanner2.png'
import wxPublish from '@/images/wxPublish.png'
import micon1 from '@/images/micon1.png'
import weiboCode from '@/images/weiboCode.png'
import micon2 from '@/images/micon2.png'
import miniProgramCode from '@/images/miniProgramCode.png'
import micon3 from '@/images/micon3.png'
import map from '@/images/map.png'
import SEO from '../templates/seo'

const About = () => {
  return (
    <article>
      <SEO
        title={'关于我们 - 别样海外购'}
        description={'别样海外购利用人工智能和大数据的技术，与欧美商家的官方网站数据直接对接，实现了欧美商家价格和活动的全球同步，为中国提供了上千个欧美时尚品牌。截至2017年，别样提供了超过八百万SKU和上万个品牌，与欧美数百个品牌商家合作，其中包括美国家喻户晓的百货公司Saks、国际时尚护肤品牌Peter Thomas Roth、美国运动鞋商家Finish Line等。'}
        keywords={'别样App,别样,别样海外购,海淘'}
        canonical='https://www.bieyangapp.com/about'
      />
      <div className="topBannerContent">
        <img className="pcImg" src={banner2} alt="banner" />
        <img className="mImg" src={mbanner2} alt="mbanner2" />
      </div>
      <div className="mainTitle">
        <span>联系我们</span>
        <span className="line"></span>
      </div>
      <div className="mainTitle2">CONTACT US</div>
      <div className="codeContents">
        <div className="weixinContent codeItem">
          <img
            className="qrcodeImg"
            src={wxPublish}
            alt="微信公众号图片" />
          <div className="codeInfoBox">
            <div className="codeInfo1">
              <img src={micon1} alt="别样官方微信公众号" />
              <span>别样官方微信公众号</span>
            </div>
            <div className="codeInfo2">
              <span className="codeLine"></span>
              <span>手机扫码即可关注</span><span className="codeLine"></span>
            </div>
          </div>
        </div>
        <div className="weiboContent codeItem">
          <img
            className="qrcodeImg"
            src={weiboCode}
            alt="别样APP" />
          <div className="codeInfoBox">
            <div className="codeInfo1">
              <img src={micon2} alt="别样APP" />
              <span>官方微博@别样APP</span>
            </div>
            <div className="codeInfo2">
              <span className="codeLine"></span>
              <span>手机扫码即可关注</span><span className="codeLine"></span>
            </div>
          </div>
        </div>
        <div className="miniContent codeItem">
          <img
            className="qrcodeImg"
            src={miniProgramCode}
            alt="别样微信小程序" />
          <div className="codeInfoBox">
            <div className="codeInfo1">
              <img src={micon3} alt="别样微信小程序" />
              <span>别样微信小程序</span>
            </div>
            <div className="codeInfo2">
              <span className="codeLine"></span>
              <span>微信扫码即可关注</span><span className="codeLine"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="usInfo">
        <div className="mapBox">
          <img src={map} alt="地图" />
        </div>
        <div className="contactInfoBox">
          <div className="contactInfoItem">
            <h3 className="infoTitle">客服热线</h3>
            <p className="info">400-623-9663</p>
          </div>
          <div className="contactInfoItem">
            <h3 className="infoTitle">在线客服</h3>
            <p className="info"><a rel='nofollow' target='_blank' href="https://bybieyang.sobot.com/chat/pc/v2/index.html?sysnum=4291aa02fcc7428c96b43b3ee59ca7ad">联系客服</a></p>
          </div>
          <div className="contactInfoItem">
            <h3 className="infoTitle">招商咨询</h3>
            <p className="info"><a rel='nofollow' href='mailto:bgmchina@borderxlab.com'>bgmchina@borderxlab.com</a></p>
          </div>
          <div className="contactInfoItem">
            <h3 className="infoTitle">联系我们</h3>
            <p className="info"><a rel='nofollow' href='mailto:contact@borderxlab.com'>contact@borderxlab.com</a></p>
          </div>
          <div className="contactInfoItem">
            <h3 className="infoTitle">海外总部地址</h3>
            <p className="info"> 111 W Evelyn Ave STE 117, Sunnyvale, CA, USA 94086 </p>
          </div>
          <div className="contactInfoItem">
            <h3 className="infoTitle">中国总部地址</h3>
            <p className="info"> 上海市浦东新区张江高科科苑路399号10号楼12层 </p>
          </div>
        </div>
      </div>
      <FloatingWidget />
    </article>
  )
}

export default About